<template>
  <div class="tag-popup">
    <div class="d-flex align-center justify-between mb-12">
      <span class="typography-theme color-1" v-if="!showEditTagInput">Select tag</span>
      <span class="typography-theme color-1" v-else>Edit tag</span>
      <button id="close-tag-popup" class="cancel-btn" @click="closePopup"><Icon name="cancel-btn" /></button>
    </div>
    <ul class="tag-list typography-theme color-1">
      <li v-for="tag in foundTags" :key="tag.name">
        <a href="" class="d-flex align-center w-100" @click.prevent="choice(tag.name)">
          <span class="tag-icon tag-icon--width mr-8" :style="'background:' + tag.color"></span>
          <span class="tag-name">{{tag.name}}</span>
        </a>
        <button @click="showEditInput(tag)" class="show-tag-edit-input"><Icon name="edit" /></button>
      </li>
    </ul>
    <div class="popup-input" v-if="!showEditTagInput">
      <input type="text" placeholder="Enter tag name"
        v-model="tagName"
        @input="inputTag"
        class="input-text"
        id="edit-input"
        @keyup.enter="choice(tagName)"
        @focus="showButtons = true"
        maxlength="24">
      <button class="mr-5 save-tag" @click="choice(tagName)" v-if="tagName.trim() && tagName.length >= 3 && showButtons">
        <Icon name="ok" />
      </button>
      <button v-if="showButtons" @click="clearInput" class="clear-tag-input"><Icon name="cancel" /></button>
    </div>
    <div class="popup-input" v-else>
      <input
        type="text"
        placeholder="Enter tag name"
        v-model="tagName"
        class="enter-tag-name input-text"
        ref="edit-input"
        @keyup.enter="editTag(tagName)"
        @focus="showButtons = true"
        maxlength="24"
      >
      <button class="mr-5 edit-tag" @click="editTag(tagName)" v-if="tagName.trim() && tagName.length >= 3 && showButtons">
        <Icon name="ok" />
      </button>
      <button v-if="showButtons" @click="clearInput" class="clear-tag-input"><Icon name="cancel" /></button>
    </div>
   <div class="triangle"></div>
  </div>
</template>

<script>
import Icon from './app/Icon';
import { eventListener } from '../utils/helpers';

export default {
  name: 'TagPopup',
  components: {
    Icon
  },
  props: ['profiles', 'mapScreen', 'openInMap'],
  emits: ['closeTagsPopup', 'showSavedTag', 'currentTag'],
  data () {
    return {
      tagName: '',
      foundTags: [],
      showButtons: false,
      showEditTagInput: false,
      tagId: null,
      profilesConnection: false,
      profilesInConnection: [],
      selectedTag: '',
      targetsMap: {},
      setOfResults: new Set(),
      existingTag: null,
      savedMapScreen: null,
      popupData: null
    };
  },
  beforeUnmount () {
    eventListener.off('saveProfileInTag');
  },
  mounted () {
    this.profiles.map(i => {
      this.popupData = i;
    });
    eventListener.on('saveProfileInTag', (name) => {
      this.saveNewOrExistingTag(name);
    });
  },
  computed: {
    profileDetails () {
      if (this.popupData) {
        return this.profiles.every(i => i.fields['user-details']);
      }
      return false;
    },
    tags () {
      return this.$store.getters.getTags;
    },
    areConnections () {
      if (!this.profiles.length) {
        return false;
      }
      const fields = this.profiles[0].fields;
      return fields && fields.connection && fields.connection.value === 'true';
    }
  },
  methods: {
    connectedProfiles () {
      this.profilesConnection = this.profilesInConnection.every(i => i.fields['user-details']);
    },
    choice (name) {
      this.selectedTag = name;
      this.checkProfiles();
      this.connectedProfiles();
      if (this.openInMap) {
        this.saveNewOrExistingTag(name);
      } else if (this.profileDetails || (this.profilesConnection && this.profilesInConnection.length)) {
        this.saveNewOrExistingTag(name);
      } else {
        this.$store.commit('updateProfilesToEnrichment', this.profiles);
        this.$store.commit('updateTagName', name);
        this.$store.commit('openInfoModal', true);
      }
    },
    inputTag () {
      this.foundTags = [];
      const re = /[[\\^$*()]/;
      if (re.test(this.tagName)) {
        return this.tagName.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');
      }
      if (this.tags && this.tags.length && this.tagName.length) {
        this.tags.forEach(tag => {
          const res = tag.name.match(this.tagName);
          if (res && res.length) {
            this.foundTags.push(tag);
          }
        });
      }
    },
    checkProfiles (tagName) {
      this.existingTag = this.tags.find(tag => {
        return tag.name === tagName;
      });
      if (this.popupData) {
        for (const profile of this.profiles) {
          if (this.existingTag && profile.tags && profile.tags.length && profile.tags.includes(this.existingTag.oid)) {
            continue;
          }
          if (profile?.fields?.relation?.value) {
            const connProfiles = this.$store.getters.sortedConnections.filter(conn => {
              return (profile.parent_id === conn.oid) || (profile.child_id === conn.oid) || (profile.oid === conn.parent_id);
            });
            const results = this.$store.getters.results.filter(res => {
              return (profile.parent_id === res.oid) || (profile.child_id === res.oid) || (profile.oid === res.parent_id);
            });
            const connsBetween = this.$store.getters.mergedConnectionsBetween.filter(conn => {
              return (profile.parent_id === conn.oid) || (profile.child_id === conn.oid) || (profile.oid === conn.parent_id);
            });
            for (const entity of [...connProfiles, ...results, ...connsBetween]) {
              if (!entity.tags || !entity.tags.includes(this.existingTag && this.existingTag.oid)) {
                this.targetsMap[entity.oid] = { type: 'entity', id: entity.oid };
                this.setOfResults.add(entity.oid);
              }
              this.profilesInConnection.push(entity);
            }
            this.targetsMap[profile.oid] = { type: 'entity', id: profile.oid };
            this.setOfResults.add(profile.oid);
            continue;
          }
          this.targetsMap[profile.oid] = { type: 'entity', id: profile.oid };
          const parent = profile.parent;
          if (parent && (!parent.tags || !parent.tags.includes(this.existingTag && this.existingTag.oid))) {
            this.targetsMap[parent.oid] = { type: 'entity', id: parent.oid };
            this.setOfResults.add(parent.oid);
          }
          this.setOfResults.add(profile.oid);
        }
      }
    },
    saveScreen () {
      this.savedMapScreen = this.mapScreen;
    },
    async saveNewOrExistingTag (tagName) {
      if (tagName.trim() === '' || tagName.length < 3) return;
      try {
        this.$store.commit('setLoading', true);
        const payload = {
          method: 'POST',
          url: '/api/user/tags',
          body: {
            targets: []
          }
        };
        this.saveScreen();
        this.checkProfiles(tagName);
        if (this.popupData) {
          const updateResultsInfo = {};
          if (this.setOfResults.size) {
            updateResultsInfo.searchItems = this.setOfResults;
          }

          Object.keys(this.targetsMap).forEach(oid => payload.body.targets.push(this.targetsMap[oid]));

          let newTags;
          let sendObjToEmit;
          if (!this.existingTag) {
            payload.body.name = tagName;
            payload.body.screen = this.savedMapScreen;
            newTags = await this.$store.dispatch('ajaxWithTokenRefresh', payload);
            const tagsWithColor = await this.$store.dispatch('setTagColors', newTags.data);
            this.$store.commit('updateTags', tagsWithColor);
            updateResultsInfo.tag = newTags.data[newTags.data.length - 1].oid;
            this.$store.commit('updateTagsInConnections', updateResultsInfo);
            this.$store.commit('updateTagsInResults', updateResultsInfo);
            this.$store.commit('updateTagsInEdges', updateResultsInfo);

            sendObjToEmit = {
              name: tagName,
              color: tagsWithColor[tagsWithColor.length - 1].color
            };
          } else if (Object.keys(updateResultsInfo).length) {
            payload.body.id = this.existingTag.oid;
            payload.body.screen = this.savedMapScreen;
            newTags = await this.$store.dispatch('ajaxWithTokenRefresh', payload);
            updateResultsInfo.tag = this.existingTag.oid;
            this.$store.commit('updateTagsInConnections', updateResultsInfo);
            this.$store.commit('updateTagsInResults', updateResultsInfo);
            this.$store.commit('updateTagsInEdges', updateResultsInfo);

            sendObjToEmit = {
              name: tagName,
              color: this.existingTag.color
            };
          }
          if (this.existingTag) {
            eventListener.emit('addInfoInTag', this.existingTag);
          }

          // We send the tag data, which we save to show it in the popup component
          this.$emit('showSavedTag', sendObjToEmit);
          this.closePopup();
          this.$store.commit('setLoading', false);
        } else if (this.existingTag) {
          this.$emit('currentTag', this.existingTag);
          this.$store.commit('setLoading', false);
        }
      } catch (error) {
        console.error(error);
      }
    },
    clearInput () {
      this.tagName = '';
      this.showButtons = false;
      this.showEditTagInput = false;
    },
    closePopup () {
      this.tagName = '';
      this.$emit('closeTagsPopup');
    },
    showEditInput (tag) {
      this.showEditTagInput = true;
      this.tagName = tag.name;
      this.tagId = tag.oid;
      this.$nextTick(() => {
        this.$refs['edit-input'].focus();
      });
    },
    async editTag () {
      if (this.tagName.length < 3) return;
      try {
        const tag = {
          id: this.tagId,
          name: this.tagName
        };
        await this.$store.dispatch('editTag', tag);
        this.tagName = '';
        this.tagId = null;
        this.showEditTagInput = false;
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .fixed {
    position: absolute;
    left: -8px;
    bottom: -28px;
    .tag-list {
    max-height: 65px;
    }
  }
</style>
